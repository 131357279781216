'use client';
import { i18n } from '@/i18n/i18n';
import { Link } from '@/i18n/routing';
import { GB, GR } from 'country-flag-icons/react/3x2';
import { usePathname } from 'next/navigation';

export const LocaleSwitcher = () => {
  const activeLocale = usePathname();
  const activeUS = activeLocale?.includes('en-US');
  const activeEL = activeLocale?.includes('el');

  return (
    <Link
      locale={!activeUS ? i18n.locales[0] : i18n.locales[1]}
      href={'/'}
      className={`btn btn-square self-center btn-neutral`}
    >
      <span>
        {!activeUS && <GB width={24} height={24} />}
        {!activeEL && <GR width={24} height={24} />}
      </span>
    </Link>
  );
};
