'use client';
import { usePathname } from '@/i18n/routing';
import { useSearchParams } from 'next/navigation';
import Script from 'next/script';
import { useEffect, useState } from 'react';

export default function GoogleAnalytics({
  GA_MEASUREMENT_ID,
}: { GA_MEASUREMENT_ID: string }) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    const colorSchemeHandler = (event: MediaQueryListEvent) => {
      const clientHasDarkModePreset = event.matches;
      const newColorScheme = clientHasDarkModePreset ? 'dark' : 'light';

      window.localStorage.setItem('color-theme', newColorScheme);

      document.documentElement.dataset.theme = newColorScheme;
    };

    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', colorSchemeHandler);
    setIsClient(true);

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', colorSchemeHandler);
    };
  }, []);

  useEffect(() => {
    const url = pathname + searchParams.toString();

    isClient && pageview(GA_MEASUREMENT_ID, url);
  }, [pathname, searchParams, GA_MEASUREMENT_ID, isClient]);

  if (!isClient) return null;

  return (
    <>
      <Script
        strategy="afterInteractive"
        src="https://cdn.jsdelivr.net/npm/theme-change@2.4.0/index.js"
      />
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('consent', 'default', {
                    'analytics_storage': 'denied'
                });

                gtag('config', '${GA_MEASUREMENT_ID}', {
                    page_path: window.location.pathname,
                });
                `,
        }}
      />
    </>
  );
}

export function pageview(GA_MEASUREMENT_ID: string, url: string) {
  window.gtag('config', GA_MEASUREMENT_ID, {
    page_path: url,
  });
}
